import React from "react"

export const ArrowOutwardIcon = ({ width = 24, height = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    width={width}
    height={height}
  >
    <path d="M6.294 17.644 5.25 16.6l9.84-9.85H6.144v-1.5h11.5v11.5h-1.5V7.804l-9.85 9.84Z" />
  </svg>
)
